<template>
  <div
    id="dashboard"
    class="fill-height d-flex align-stretch"
    v-resize="onResize"
  >
    <dashboard-nav
      v-if="!report.toggle" 
      :pages="allowedPages"
      :scope="campaign.id"
      :campaigns="brandCampaigns"
      :brand="brand"
      :children="campaign.groups"
      :loading="views.campaigns.loading"
      :admin="admin"
      :user="user"
      :auditor="auditor"
      :size="device.size"
      :mobile="device.mobile"
      class="flex-shrink-0"
      @nav-fixed="onNavFixed"
    />

    <v-container 
      id="report"
      fluid 
      class="dashboard-container flex-grow-1 pr-0 pt-0 pb-0 d-flex flex-column"
      :class="{ 'printable': report.printable }"
      :style="{'padding-left': report.toggle||breakpoint(null, 'xs') ? '0px' : views.nav ? '280px' : '56px' }"
    >
      <v-toolbar 
        flat
        tile
        :height="breakpoint('md') ? '120' : '96'"
        color="transparent"
        class="px-2 pr-4 pr-md-2 py-0 align-center flex-shrink-0 flex-grow-0"
      >
        <v-toolbar-title class="text-h4 font-weight-light px-4 d-flex">
          {{ title }}
          <campaign-filter
            :scope="campaign.id"
            :campaigns="brandCampaigns"
            :status="views.campaigns.loader"
            :loading="views.campaigns.loading"
            :children="childrenList"
            :children-filter="campaign.groups"
            :cities="cities"
            :admin="admin"
            ref="campaign-filter" 
            class="scrollable mx-4"
            @toggle-outline="toggleOutline"
            @updated="onCampaignUpdate"
            @update-children="onGroupsUpdate"
          />
        </v-toolbar-title>

        <v-spacer />

        <!-- <v-menu
          v-model="views.cities.toggle"
          transition="scroll-y-transition"
          origin="top right"
          min-width="25%"
          max-width="320px"
          offset-y
          right
          fixed
          class="city-selection-control"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              :text="breakpoint('xs') ? true : false"
              :icon="breakpoint('xs') ? false : true"
              :disabled="views.cities.disabled"
              small
              height="40"
              color="grey darken-2"
              class="city-btn d-block text-right text-truncate mr-2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon 
                size="18"
                :class="{ 'mr-3': breakpoint('xs') }"
              >
                {{ icons.city }}
              </v-icon>
              {{ cityData | cityDisplay(cityOptions) }}
            </v-btn>
          </template>
          <v-card>
            <m-city-select
              :cities="cityOptions"
              clickable
              :max-height="320"
              @click="onCityChange"
            />
          </v-card>
        </v-menu> -->

        <period-filter
          :period="filters.period"
          :min="campaign.start"
          :max="campaign.end"
          :loading="views.campaigns.loading"
          ref="period" 
          class="filter period"
          @updated="onPeriodUpdate"
        />
        <v-menu
          v-model="report.hint.toggle"
          :disabled="report.toggle"
          :open-on-hover="!report.loading&&report.hint.shown"
          transition="scroll-y-transition"
          max-width="320px"
          nudge-bottom="8"
          offset-y
          left
          close-delay="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="!report.toggle"
              fab
              icon
              small
              :elevation="report.hint.toggle&&!report.hint.shown ? 8 : 0"
              color="accent"
              class="mr-2"
              :loading="report.loading"
              v-bind="attrs"
              v-on="on"
              @click="downloadReport('image')"
            >
              <v-icon
                size="20"
              >
                {{ icons.download }}
              </v-icon>
            </v-btn>
          </template>    
          <v-card>
            <v-card-title 
              class="accent--text text-subtitle-2 font-weight-bold"
            >
              Relatório PDF
            </v-card-title>
            <v-divider />
            <v-card-text
              v-if="report.loading"
            >
              O relatório está sendo processado, isso pode demorar alguns minutos...
            </v-card-text>
            <v-card-text 
              v-else
            >
              Clique no botão <v-icon color="accent" size="18">{{ icons.download }}</v-icon> para baixar Relatório PDF completo com os dados para a Campanha e o período selecionados.
            </v-card-text>
            <v-card-actions 
              v-if="!report.hint.shown"
              class="pt-0"
            >
              <v-spacer />
              <v-btn 
                text 
                color="accent"
                @click="hideReportHint"
              >
                Ok!
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-toolbar>

      <v-fade-transition mode="out-in">
        <router-view 
          :pages="pages"
          :segment="segment"
          :segments="segments"
          :campaigns="selectedCampaigns"
          :scope="campaign.id"
          :children="campaign.groups"
          :period="filters.period"
          :cities="cities"
          :city="cityData"
          :metric="filters.metric"
          :layer="filters.layer"
          :layers="layers"
          :budget="totalBuget"
          :impressions="impressions"
          :duration="duration"
          :data-key="dataKey"
          :report="report.toggle"
          :user="user"
          :stored="store"
          :auditor="auditor"
          :nav="views.nav"
          class="px-6 pb-12 flex-grow-1"
          @store-data="onStoreData"
          @update="onDataUpdate"
          @metric-change="onMetricChange"
          @city-change="onCityChange"
          @layer-change="onLayerChange"
        />
      </v-fade-transition>
    </v-container>

    <v-overlay 
      :value="campaign.id==null&&!views.campaigns.updated"
      color="white"
      absolute
      opacity=".8"
      class="loading-overlay text-center"
    >
      <loading class="loading mb-4" />
      <span class="d-block text-overline grey--text text--lighten-1">
        Carregando
      </span>
    </v-overlay>
  </div>
</template>

<style type="text/css">

  .hint-card {
    margin-top: 10px;
  }

  #dashboard {
    background: transparent;
  }
  #dashboard > .v-window {
    background: transparent;
  }

  #dashboard .dashboard-container {
    will-change: padding-left;
    transition: padding-left 150ms ease-out;
  }

  #dashboard .toolbar .divider {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  #dashboard .tabs .v-tab {
    text-transform: initial;
    /* border-bottom: 1px solid rgba(38,39,44,.16); */
  }

  #dashboard .segment {
    background: transparent !important;
  }
  
  #dashboard .v-toolbar__content {
    padding: 0;
  }

  #dashboard .loading-overlay .v-overlay__content {
    text-align: center;
  }
  #dashboard .loading-overlay .loading {
    width: 48px;
    height: 48px;
    opacity: .8;
  }

</style>

<script>
  import services from '@/services'
  import { download as ApiDownloadReport } from '@/api/dashboard'
  import { list as getCampaigns, parseCities } from '@/api/campaigns'
  import { list as getAds, parsePois } from '@/api/ads'
  import { sync } from 'vuex-pathify'
  import { 
    mdiDownload,
    mdiCityVariantOutline
  } from '@mdi/js';
  import _ from 'lodash';
  const moment = require('moment');
  import device from 'mobile-device-detect';

  export default {
    name: 'Dashboard',
    metaInfo: {
      title: 'Dashboard'
    },

    components: {
      DashboardNav: () => import('./Nav'),
      PeriodFilter: () => import('@/components/PeriodFilter'),
      CampaignFilter: () => import('@/components/CampaignFilter'),
      // MCitySelect: () => import('@/components/mCitySelect'),
      Loading: () => import('@/components/IconLoading'),
    },

    props: {
      admin: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      icons: {
        download: mdiDownload,
        city: mdiCityVariantOutline
      },
      pages: {
        overview: {
          title: 'Overview',
          path: '/',
          loading: false,
          report: true
        },
        live: {
          title: 'Timelapse',
          path: '/timelapse',
          loading: false,
          report: false
        },
        map: {
          title: 'Mapa',
          path: '/map',
          loading: false,
          report: {
            queries: ['metric=audience']
          }
        },
        demographics: {
          title: 'Perfil Demográfico',
          path: '/demographics',
          loading: false,
          order: 1,
          report: {
            queries: ['charts=age,gender', 'charts=income']
          }
        },
        audience: {
          title: 'Impactos',
          path: '/audience',
          loading: false,
          order: 0,
          report: {
            queries: ['charts=audience,daily', 'charts=hourly']
          }
        },
        impressions: {
          title: 'Impressões',
          path: '/impressions',
          loading: false,
          order: 2,
          report: {
            queries: ['charts=daily', 'charts=hourly']
          }
        },
        budget: {
          title: 'Investimento',
          path: '/budget',
          loading: false,
          roles: [1,5,6,7],
          order: 3,
          report: {
            queries: ['charts=daily', 'charts=hourly']
          }
        },
        cpm: {
          title: 'CPM',
          path: '/cpm',
          roles: [1,5,6,7],
          loading: false,
          order: 4,
          report: {
            queries: ['charts=daily', 'charts=hourly']
          }
        },
        hours: {
          title: 'Horas no ar',
          path: '/hours',
          loading: false,
          order: 5,
          report: {
            queries: ['charts=daily', 'charts=hourly']
          }
        },
        gallery: {
          title: 'Galeria',
          path: '/gallery',
          loading: false,
          order: 6,
          report: false
        },
      },
      views: {
        nav: false,
        campaigns: {
          updated: false,
          loading: false,
          error: 0,
          loader: {}
        },
        cities: {
          toggle: false,
          disabled: true,
          options: [],
          data: null
        },
      },
      campaign: {
        id: null,
        groups: [],
        start: null,
        end: null,
        outline: false
      },
      layers: {
        city: { value: 'cities', text: 'Cidades' }, 
        zones: { value: 'zones', text: 'Zonas' }, 
        geofences: { value: 'geofences', text: 'Bairros' }, 
        pois: { value: 'pois', text: 'Hotspots' }
      },
      report: {
        toggle: false,
        printable: false,
        disabled: false,
        loading: false,
        hint: {
          toggle: false,
          shown: false
        }
      },
      device: {
        size: { width: 0, height: 0 },
        mobile: device.isMobileOnly,
      }
    }),

    computed: {
      view: sync('app/views@dashboard'),
      filters: sync('dashboard/data@filters'),
      segment: sync('dashboard/data@segments.selected'),
      segments: sync('dashboard/data@segments.items'),
      store: sync('dashboard/data@cache'),
      campaigns: sync('campaigns/items'),
      brands: sync('clients/items'),
      supported: sync('app/views@map.cities'),
      user: sync('user/data'),
      toast: sync('app/toast'),

      title () {
        return this.$route.name;
      },

      brand () {
        return parseInt(this.$route.params.brand);
      },

      brandData () {
        return this.brands[this.brand];
      },

      allowedPages () {
        const brand = this.brand;
        return _.omitBy(this.pages, p => {
          return _.has(p, 'clearance') && this.hasClearance(brand, p.clearance)==false
        })
      },

      brandCampaigns () {
        const campaigns = _.pickBy(this.campaigns, ['brand.id', this.brand]);
        console.log('brand campaigns:', campaigns);
        return campaigns;
      },

      selectedCampaigns () {
        const filters = this.filters;
        const list = [filters.campaign, ...filters.groups];
        console.log('selected campaigns:', list, filters.groups);
        const campaigns = _.pickBy(this.brandCampaigns, c => {
          const include = _.indexOf(list, c.id)>=0;
          return include;
        });
        return campaigns //_.filter(campaigns, (c) => c.active && c.visible && (_.indexOf(children, c.id) < 0));
      },

      childrenList () {
        let children = null;
        const campaigns = this.brandCampaigns;
        let campaign = this.campaign.id;
        if (_.size(campaigns)>0&&campaign!=null&&_.has(campaigns, campaign)) {
          campaign = this.campaigns[campaign];
          children = campaign.children.length > 0 ? {} : null;
          if (children!=null){
            _.each(this.campaigns[campaign.id].children, c => {
              children[c] = this.campaigns[c];
            });
          }
        }
        return children;
      },

      cities () {
        return _.isNil(this.brandData) ? {} : _.pick(this.supported, this.brandData.cities);
      },

      // campaignCities () {
      //   const list = _.reduce(this.selectedCampaigns, (cities, campaign) => {
      //     const c = campaign.cities!=null||_.isEmpty(campaign.cities) ? [3] : _.map(campaign.cities, 'id');
      //     cities = _.union(cities, c);
      //     return cities;
      //   }, [])
      //   return _.pick(this.cities, list);
      // },

      cityOptions () {
        const cities = this.cities;
        const options = _.intersection(_.clone(this.views.cities.options), _.map(cities, 'id'));
        console.log('city options', options, cities);
        return {
          null: {
            id: null,
            title: 'Todas',
          },
          ..._.mapValues(_.keyBy(options), id => {
            const { title } = cities[id];
            return { id, title };
          })
        }
      },

      cityData () {
        const cities = this.cities;
        const city = this.filters.city;
        return _.isNil(city) ? null : cities[city];
      },

      auditor () {
        return _.indexOf(this.user.roles, 9)>=0;
      },

      dataKey () {
        const period = this.filters.period != null ? this.filters.period.join(',') : null;
        const campaign = this.campaign.id;
        const groups = this.campaign.groups!=null ? _.isEmpty(this.campaign.groups) ? null : this.campaign.groups.join(',') : null;
        const user = this.user.username;
        const key = `${campaign}/${groups}/${period}/${user}`;
        // console.log(key);
        return key;
      },

      totalBuget () {
        let budget = 0;
        if (!_.isNil(this.campaign.id)) {
          const campaign = this.campaigns[this.campaign.id];
          const groups = this.campaign.groups;
          const campaigns = _.isNil(groups) || _.isEmpty(groups) ? [campaign] : _.map(groups, id => this.campaigns[id]);
          budget = _.reduce(campaigns, (total, c) => {
            return total + c.budget;
          }, 0);
        }
        return budget;
      },

      impressions () {
        const $ = this;
        const campaign = $.filters.campaign;
        return _.size(this.brandCampaigns)==0 ? 0 : _.reduce([campaign], (result, id) => {
          result.total += $.campaigns[id].impressions;
          return result;
          // const contract = _.has($.campaigns, id) ? $.campaigns[id].contract : null;
          // return _.isNil(contract) || !_.has($.contracts, contract) || _.indexOf(result.contracts, contract)>=0 ? result : {
          //   total: result.total + $.contracts[contract].impressions,
          //   contracts: [contract, ...result.contracts]
          // }
        }, { total: 0, contracts: [] }).total;
      },

      contracts () {
        const brand = !_.isNil(this.brand) && _.has(this.brands, this.brand) ? this.brands[this.brand] : null;
        const contracts = !_.isNil(brand) && _.has(brand, 'contracts') ? brand.contracts : [];
        return _.mapValues(_.clone(contracts), contract => {
          let advertiser = _.find(brand.advertisers, ['id', contract.advertiser]);
          advertiser = _.isNil(advertiser.agency) ? brand.title : advertiser.agency.name;
          return {
            ...contract,
            advertiser,
          }
        });
      },

      duration () {
        let period;
        if (!_.isNil(this.campaign.id)) {
          const groups = this.campaign.groups;
          const id = this.campaign.id;
          const campaigns = _.isNil(groups) || _.isEmpty(groups) ? [this.campaigns[id]] : _.map(groups, id => this.campaigns[id]);
          period = _.reduce(campaigns, (period, campaign) => {
            return {
              start: moment(period.start).isBefore(campaign.period.start) ? period.start : campaign.period.start,
              end: moment(period.end).isAfter(campaign.period.end) ? period.end : campaign.period.end,
            }
          }, { start: moment().format('YYYY-MM-DD'), end: moment(0).format('YYYY-MM-DD') });
        }else{
          period = {
            start: moment().subtract(7, 'd').format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD'),
          }
        }
        // console.log('segment period total:', period.start, period.end);
        return [period.start, period.end];
      },
    },

    watch: {
      $route: {
        immediate: true,
        handler (route) {
          if (!_.isNil(this.brand)) {
            console.log('route', route.params);
            this.updateView();
          }
        }
      },
      'filters.campaign' (campaign) {
        const route = _.has(this.$route.params, 'campaign') ? this.$route.params.campaign : null;
        if (campaign!=route) {
          this.onCampaignUpdate(campaign);
        }
      },
      'filters.groups' (groups) {
        const route = _.has(this.$route.params, 'groups') ? this.$route.params.groups : null;
        if (groups!=route) {
          this.onGroupsUpdate(groups);
        }
      },
      'filters.period' (period) {
        const route = !_.isNil(this.$route.params.period) ? _.split(this.$route.params.period, ',') : null;
        if (!_.isEqual(period, route)) {
          this.onPeriodUpdate(period);
        }
      },
      campaigns: {
        immediate: true,
        deep: true,
        handler (campaigns) {
          if (!this.views.campaigns.updated&&!this.views.campaigns.loading) {
            this.loadCampaigns();
          }

          // build loader structure
          _.each(campaigns, campaign => {
            if (!_.has(this.views.campaigns.loader, campaign.id)) {
              this.$set(this.views.campaigns.loader, campaign.id, {
                loading: false,
                updated: false,
                archived: false
              });
            }
          });

          this.updateView();
        }
      },
    },

    filters: {
      cityDisplay (city, options) {
        return city==null||!_.has(options, city.id) ? 'Todas' : options[city.id].title;
      }
    },

    methods: {
      ...services,
      getCampaigns,
      parseCities,
      getAds,
      parsePois,
      ApiDownloadReport,

      onNavFixed (b) {
        this.views.nav = b;
      },

      hasClearance (brand, cleared) {
        return _.indexOf(cleared, brand)>=0;
      },

      toggleOutline (b) {
        this.campaign.outline = _.isNil(b) ? !this.campaign.outline : b;
      },

      parseQuery (route) {
        this.report.toggle = (_.has(route.query, 'report')&&route.query.report==1);

        if (_.has(route.query, 'metric')) {
          this.onMetricChange(route.query.metric);
        }
      },

      updateView () {
        const route = this.$route;
        this.parseQuery(route);
        if (_.size(this.campaigns)>0) {
          if (_.size(this.brandCampaigns)==0) {
            if (this.admin) {
              this.$router.replace({
                name: 'Campanhas',
                params: { brand: route.params.brand },
                query: route.query
              }).catch((error) => {
                console.warn(error)
              });
            }
          }else{
            let campaign;
            let changed = true;
            if (_.isNil(route.params.campaign)||route.params.campaign=='') {
              if (this.filters.campaign==null||!_.has(this.brandCampaigns, this.filters.campaign)) {
                const list = _.orderBy(_.filter(this.brandCampaigns, ['visible', 1]), [c=>c.airing ? 1 : 0, 'period.start', 'period.end'], ['desc', 'desc', 'desc']);
                this.filters.campaign = _.first(list).id;
              }else{
                campaign = this.filters.campaign;
                console.log('campaign', campaign);
                this.$router.push({
                  name: 'Overview',
                  params: _.assign(route.params, { 
                    campaign,
                    period: _.join(this.campaignPeriod(this.campaigns, campaign), ','),
                    }, _.isEmpty(this.campaigns[campaign].children) ? {} : { groups: _.join(this.campaigns[campaign].children, ',') }),
                  query: route.query
                }).catch((error) => {
                  console.warn(error)
                });
              }
              return;
            }else{
              campaign = parseInt(route.params.campaign);
              changed = this.filters.campaign != campaign;
              this.filters.campaign = campaign;
              this.campaign.id = parseInt(route.params.campaign);
            }
            if (this.campaign.id!=null) {
              const groups = _.isNil(route.params.groups) ? [] : _.map(_.split(route.params.groups, ','), group => parseInt(group));
              changed = changed ? changed : !_.isEqual(this.filters.groups, groups);
              this.filters.groups = groups;
              this.campaign.groups = groups;

              if (!_.has(this.views.campaigns.loader, this.campaign.id)) {
                this.$set(this.views.campaigns.loader, this.campaign.id, {
                  loading: false,
                  updated: false,
                });
              }
              if (changed) {
                const cities = this.campaignCities;
                this.onCityChange(_.size(cities)==1 ? _.first(_.map(cities, 'id')) : null);
              }
              if (!this.views.campaigns.loader[this.campaign.id].updated && !this.views.campaigns.loader[this.campaign.id].loading) {
                this.loadAds(this.campaign.id);
              }
    
              let period = this.campaignPeriod(this.campaigns, this.campaign.id);
              this.campaign.start = period[0];
              this.campaign.end = period[1];
              if (_.isNil(route.params.period)) {
                this.$router.replace({
                  name: 'Overview',
                  params: _.assign(route.params, { 
                    period: _.join(period, ','),
                  }),
                  query: route.query
                }).catch((error) => {
                  console.warn(error)
                });
              }else{
                period = _.split(route.params.period, ',');
                if (changed||!_.isEqual(this.filters.period, period)) {
                  this.filters.period = period;
                }
              }
            }
          }
        }
      },

      loadCampaigns () {
        this.views.campaigns.loading = true;
        this.getCampaigns(
          this.user.auth.token,
          this.admin,
          (campaigns) => { 
            let del = _.map(this.campaigns, c => c.id);
            _.each(campaigns, campaign => {
              if (campaign.children.length>0) {
                const [ start, end ] = this.campaignPeriod(campaigns, campaign.id, campaign.children, true);
                campaign.airing = _.some(campaign.children, child => campaigns[child].airing);
                campaign.active = _.some(campaign.children, child => campaigns[child].active==1) ? 1 : 0;
                campaign.visible = _.some(campaign.children, child => campaigns[child].visible==1) ? 1 : 0;
                campaign.period.start = start;
                campaign.period.end = end;
                campaign.impressions = _.reduce(campaign.children, (sum, child) => {
                  return sum + campaigns[child].impressions;
                }, 0);
                campaign.budget = _.reduce(campaign.children, (sum, child) => {
                  return sum + campaigns[child].budget;
                }, 0);
                if (_.has(campaign, 'edition')&&!_.isNil(campaign.edition)) campaign.edition.budget = _.reduce(campaign.children, (sum, child) => {
                  return sum + (campaigns[child].approval==1 ? campaigns[child].budget : campaigns[child].edition.budget);
                }, 0);
                campaign.approval = _.every(campaign.children, child => {
                  return campaigns[child].approval==1;
                }) ? 1 : null;
                campaign.paid = 2;
              }
              const ads = _.has(this.campaigns, campaign.id) ? this.campaigns[campaign.id].ads : {}
              this.$set(this.campaigns, campaign.id, _.assign(campaign, { ads }));
              if (campaign.visible==1||this.admin) del = _.without(del, campaign.id);
            });
            _.each(del, c => this.$delete(this.campaigns, c));
          },
          (error) => { 
            if (error.response.status != 404) {
              this.views.campaigns.error += 1;
              if (_.size(this.campaigns)==0&&this.views.campaigns.error>5) {
                this.toggleToast(
                  true,
                  'Aguardando resposta da nuvem...',
                  7000,
                  false
                );
              }
              setTimeout(this.loadCampaigns, 5000);
            }
          },
          () => { 
            this.views.campaigns.updated = true;
            this.views.campaigns.loading = false;
          }
        );
      },

      loadAds (campaign, archived) {
        if (this.report.toggle||_.isNil(campaign)) return;
        this.views.campaigns.loader[campaign].loading = true;
        archived = !_.isNil(archived)&&archived;
        this.getAds(
          this.user.auth.token,
          campaign,
          archived ? [0] : [1,2],
          (campaign, ads) => {
            this.campaigns[campaign].ads = Object.assign({}, this.campaigns[campaign].ads, ads);
            if (archived) {
              this.views.campaigns.loader[campaign].archived = true;
            }else{
              this.views.campaigns.loader[campaign].updated = true;
            }
            let pending = _.find(this.campaigns[campaign].children, child => {
              return _.has(this.views.campaigns.loader, child.id) && !this.views.campaigns.loader[child.id].updated && !this.views.campaigns.loader[child.id].loading;
            });
            pending = _.isNil(pending) ? _.find(this.brandCampaigns, campaign => {
              return _.has(this.views.campaigns.loader, campaign.id) && !this.views.campaigns.loader[campaign.id].updated && !this.views.campaigns.loader[campaign.id].loading;
            }) : pending;
            if (!_.isNil(pending)) this.loadAds(pending.id);
          },
          (error) => { 
            // this.toggleToast(
            //   true,
            //   'Aguardando resposta da nuvem...',
            //   7000,
            //   false
            // );
            // setTimeout(this.loadAds, 5000, campaign);
          },
          () => { 
            this.views.campaigns.loader[campaign].loading = false;
          }
        )
      },

      onDataUpdate (b, data) {
        console.log('Data updated:', b);
        let cities = []
        if (b) {
          data = Object.freeze(_.mapValues(data, location => {
            return _.groupBy(location.data, 'city.id');
          }))
          cities.push(..._.map(_.keys(data.geofences), id => parseInt(id)));
        }
        this.views.cities.options = cities;
        this.views.cities.data = b ? data : null;
        this.views.cities.toggle = this.views.cities.toggle&&b || false;
        this.views.cities.disabled = !b;
        this.views.cities.suggested = !b;

        this.autoSelectLayer();
        
        this.onReportLoad(b);
      },

      onStoreData (data) {
        this.store = Object.assign(this.store, { 
          [data.dimension]: { 
            key: data.key,
            source: data.source,
            timestamp: moment().valueOf(), 
          }
        });
      },

      onMetricChange (metric) {
        console.log('metric change: '+metric);
        this.filters.metric = metric;
      },

      onLayerChange (layer) {
        console.log('layer change: '+layer);
        this.filters.layer = layer;
        if (layer=='cities'&&this.filters.city!=null) this.onCityChange(null);
      },

      onCityChange (city) {
        console.log('city change: '+city);
        this.filters.city = city;
        this.autoSelectLayer(city);
      },

      autoSelectLayer (city=null) {
        city = city || this.filters.city;
        const cities = this.views.cities;
        if (!this.views.cities.suggested&&_.size(cities.options)==1&&city==null) {
          this.onCityChange(_.first(cities.options));
          this.views.cities.suggested = true;
        }
        if (city!=null&&_.has(cities.data, 'pois')) {
          this.onLayerChange(_.has(cities.data.pois, city) ? 'pois' : 'geofences');
        }
      },

      onCampaignUpdate (campaign) {
        if (this.campaign.id!=campaign){
          const hasGroups = campaign!=null ? this.campaigns[campaign].children.length>0 : false;
          const groups = hasGroups ? this.campaigns[campaign].children : null;
          const period = this.campaignPeriod(this.campaigns, campaign);

          this.$router.push({
            name: this.$route.name,
            params: _.assign({ 
              brand: this.$route.params.brand,
              campaign: campaign, 
              period: _.join(period, ','), 
            }, _.isEmpty(groups) ? {} : { groups: _.join(groups, ',') })
          }).catch((error) => {
            console.warn(error)
          });
        }
      },
      onGroupsUpdate (groups) {
        if (!_.isEqual(this.campaign.groups, groups)){
          console.log('groups update:', groups);
          const period = this.campaignPeriod(this.campaigns, this.campaign.id, groups);

          this.$router.push({
            name: this.$route.name,
            params: _.assign({ 
              brand: this.$route.params.brand,
              campaign: this.$route.params.campaign, 
              period: _.join(period, ','), 
            }, _.isEmpty(groups) ? {} : { groups: _.join(groups, ',') })
          }).catch((error) => {
            console.warn(error)
          });
        }
      },
      onPeriodUpdate (period) {
        if (!_.isEqual(this.filters.period, period)){
          this.$router.push({
            name: this.$route.name,
            params: _.assign({ 
              brand: this.$route.params.brand,
              campaign: this.$route.params.campaign, 
              period: _.join(period, ','), 
            }, _.isNil(this.$route.params.groups) ? {} : { groups: this.$route.params.groups })
          }).catch((error) => {
            console.warn(error)
          });
        }
      },

      onReportLoad (b) {
        this.report.printable = b;
      },

      hideReportHint () {
        let hints = localStorage.getItem('hints');
        if (_.isNil(hints)) {
          hints = {
            report: true,
          }
        }else{
          hints = JSON.parse(hints);
          hints['report'] = true;
        }
        this.report.hint.toggle = false;
        this.report.hint.shown = true;
        hints = JSON.stringify(hints);
        localStorage.setItem('hints', hints);
      }, 

      downloadReport () {
        this.report.loading = true;
        const route = this.$route;
        const urls = _.reduce(_.filter(_.clone(this.pages), page => _.has(page, 'report')&&page.report!==false), (pages, page) => {
          const path = page.path == '/' ? '' : page.path;
          const params = { brand: route.params.brand, campaign: route.params.campaign, period: route.params.period, groups: _.has(route.params, 'groups')&&!_.isNil(route.params.groups) ? route.params.groups : '' }
          const url = `${process.env.VUE_APP_ROOT}/${params.brand}/dashboard${path}/${params.campaign}/${params.period}/${params.groups}?report=1`;
          return _.concat(pages, _.has(page.report, 'queries') ? _.map(page.report.queries, q => {
            return url+'&'+q;
          }) : [url]);
        }, []);
        const filename = 'GO-'+this.campaigns[this.campaign.id].title+'_'+moment().format('YYYY-MM-DD');
        const title = 'Relatório GO '+this.campaigns[this.campaign.id].title+' '+moment().format('YYYY-MM-DD');
        const user = JSON.stringify(this.user);
        const vuex = localStorage.getItem('vuex');
        const size = { width: 1440, height: 920 };
        this.ApiDownloadReport(
          this.user.auth.token,
          {
            urls,
            filename,
            title,
            params: { user, vuex, size },
          },
          (data) => {
            // resolve download
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `${filename}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
          },
          (error) => {
            
          },
          () => {
            this.report.loading = false;
          }
        )
      },

      onResize () {
        this.device.size.width = window.innerWidth;
        this.device.size.height = window.innerHeight;
      },
      onToggleView (p) {
        console.log(p)
        this.views[p.view].toggle = p.toggle;
      },
    },

    created () {
    },
    mounted () {
      this.onResize();

      let hints = localStorage.getItem('hints');
      let showReportHint = false;
      if (_.isNil(hints)) {
        showReportHint = true;
      }else{
        hints = JSON.parse(hints);
        if (_.has(hints, 'report')&&hints.report) {
          showReportHint = false;
        }else{
          showReportHint = true;
        }
      }
      setTimeout(($, showReportHint) =>  {
        if (!$.report.loading) {
          $.report.hint.toggle = showReportHint;
          $.report.hint.shown = !showReportHint;
        }
      }, 2000, this, showReportHint)
    },
  }

</script>
